import * as React from "react";
import RIShopReact from "./RIShopReact";
import RIShop from "./RIShop";

interface pMenuMain { props:any }
export class MenuMain extends React.Component<pMenuMain, {}> {

path (href : string) {
	// console.log(href, RIShopReact.urlStatic(href));
	return RIShopReact.urlWithLang(href);
}
render () {
	let lvl0 :Array<JSX.Element> = [];
	let lvl1 :JSX.Element;
	let active : string;
	this.props.props.menuMain.items.forEach((val, indx) => {
		if (!val.type) return;
		let lvl1subs :Array<JSX.Element> = [];
		lvl1 = <React.Fragment></React.Fragment>;
		if (val.subMenu) {
			val.subMenu.items.forEach((val2, indx2) => {
				if (val.subMenu.activeIndx == indx2) active = 'active'; else active = '';
				lvl1subs.push(<li key={indx2}>
					<a onClick={RIShop.clickURL.bind(this)} href={this.path(val.href+'/'+val2.href)} className={active}>
						{val2.label.replace(/ /g, '\u00A0')}&nbsp;<span className="prod-count">{val2.prodCount}</span>
					</a></li>);
			});
			lvl1 = <ul>{lvl1subs}</ul>;
		}
		if (this.props.props.menuMain.activeIndx == indx) active = 'active'; else active = '';
		lvl0.push(<li key={indx}><a onClick={RIShop.clickURL.bind(this)} href={this.path(val.href)} className={active}>
			{val.label} <span className="prod-count">{val.prodCount}</span></a>{lvl1}</li>);
	});
	//this.props.props.
	return <nav id="cats-main"><ul>{lvl0}</ul></nav>;
}

}
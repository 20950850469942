import * as React from "react";
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import AppBar from './AppBar';
import Drawers from './Drawers';
import { ItemInGrid } from "./ItemInGrid";
import { MenuMain } from "./MenuMain";
import RIShop from "./RIShop";
// import RIShop from "./RIShop";

interface iRIShopState {}
/*function App () {
	return (
		<Button variant="contained" color="primary">Hello World</Button>
	);
}*/
export interface RIShopProps { store:any }

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class RIShopReact extends React.Component<RIShopProps, {}> {
//static  urlBase = this.props.store.urlBase;

static current:RIShopReact;
static urlStatic (link) {
	return (RIShopReact.store.urlBase+link).replace(/ /ig, '-');
}
static urlWithLang (link) {
	return (RIShopReact.store.urlBase+RIShopReact.store.langActive+'/'+link).replace(/ /ig, '-');
}
static urlImg (img :string, size :number = 0, crop :boolean = false) {
	let append = '';
	if (size) append += '=s'+size.toString();
	if (size && crop) append += '-c';
	return 'https://europe-west1-ritmainstituts-lv.cloudfunctions.net/getCachedImgUrl?img='+img+'&append='+append;
}
static get store () {	return RIShopReact.current.props.store;}
static label (vari : string) {
	if (RIShopReact.store.labels.hasOwnProperty(vari) && RIShopReact.store.labels[vari].hasOwnProperty(RIShopReact.store.langActive)) {
		return RIShopReact.store.labels[vari][RIShopReact.store.langActive];
	} else return '?'+vari;
}

constructor (props) {
	super(props);
	RIShopReact.current = this;
}
render () {

	let items:Array<JSX.Element> = [];

	console.warn('RIShopReact.store.menuMain.activePath', RIShopReact.store.menuMain.activePath);
	RIShopReact.store.items.forEach((item, indx) => {
		console.log('item.path', item.catPath);
		if (item.catPath && item.catPath.startsWith(RIShopReact.store.menuMain.activePath)) {
			items.push(<ItemInGrid key={indx} props={item} />);
		}
	});

	let nonProductLVLs = [];
	RIShopReact.store.menuMain.items.forEach((lvl1, indx) => {
		if (lvl1['type']=='') return;
		let active = RIShopReact.store.menuMain.activeIndx==indx ? 'active' : '';
		nonProductLVLs.push(<React.Fragment key={indx}>
			<a onClick={RIShop.clickURL.bind(this)} href={RIShopReact.urlWithLang(lvl1['label'])} className={active}>{lvl1['label']}</a>&nbsp;
		</React.Fragment>);
	});

	 return <React.Fragment>
	 <header>
		 <div><a href={RIShopReact.urlStatic('')}><img alt={RIShopReact.store.logo.alt} src={RIShopReact.urlStatic(RIShopReact.store.logo.url)}/></a></div>
		 <div className="slogan"><span>VEIKALS</span></div>
		 <div id="menu-top-left">
			 <div className="menu-top-left-sections">{nonProductLVLs}</div>
			 <div className="shopping-cart-info-wrap">
				 <div>
					 <span className="shopping-cart-icon"></span>
					 <span className="shopping-cart-info">1<span className="small dim">prece</span><span className="dim">/</span>30,99 €</span>
				 </div>
				 <div id="langs"><a className="active" href="#">LV</a> <a href="#">EN</a> <a href="#">RU</a></div>
			 </div>
		 </div>
	 </header>
		 <MenuMain props={RIShopReact.store} />
		 <div className="breadcrumb-main">{RIShopReact.store.menuMain.activePathLabel}</div>
		 <div className="section-descr">{RIShopReact.store.menuMain.activePathDescription}</div>
		 <main className="grid-item-layout">{items}</main>
		 <footer>{RIShopReact.store.footer}</footer>
	 </React.Fragment>;

}
}

export default RIShopReact;
import * as React from "react";
import RIShopReact from "./RIShopReact";

interface pItemInGrid { props:any}
export class ItemInGrid extends React.Component<pItemInGrid, {}> {

render () {
	let img = this.props.props.img ? <img className="mainPic" src={RIShopReact.urlImg(this.props.props.img, 200)}/> : '';
	return <div className="item-in-grid">
		<h2>{/*{this.props.props.catPathTitleComposite} /*/} {this.props.props.title}</h2>
		<div className="item-in-grid-col-wrap">
			<div className="item-col-img">
				{img}
			</div>
			<div className="item-col-right">
				<p>{this.props.props.descr}</p>
				<div className="price">{RIShopReact.label('Cena')}: {this.props.props.price} {RIShopReact.label('Valūtas simbols')}</div>
				<button className="price-button">{RIShopReact.label('Ielikt grozā')}</button>
			</div>
		</div>
	</div>;
}
}
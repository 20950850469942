import * as React from "react";
import * as ReactDOM from "react-dom";

import { RIShopReact } from "./RIShopReact";
import * as Redux from 'redux';
import RIShop from "./RIShop";

RIShop.init();

// ReactDOM.render(<RIShopReact store={RIShop.STORE} />, document.getElementById("riShopReact"));
/*let initState:iRIShopState = {inc:1, wink:2};
const store = Redux.createStore(counter, initState, Redux.applyMiddleware(reduxMiddleware));
// var store = Redux.createStore(counter, {'bazooka':0, inc:2}, Redux.applyMiddleware(logger));
function reduxMiddleware ({ getState }) {
	return function (next) {
		return function (action) {
			console.warn('will dispatch', action, getState());
			const returnValue = next(action);
			console.warn('state after dispatch', getState());
			return returnValue;
		}
	}
}
function counter (state, action) {
	switch (action.type) {
		case 'INCREMENT': {
			state.inc += 1;
			return state;
		} case 'DECREMENT': {
			state.inc = 1;
			return state;
		} default:return state;
	}
}

function render () {
	console.log('store.dispatch', store.getState());
	//valueEl.innerHTML = store.getState().inc;
}

render();
store.subscribe(render);
store.dispatch({ type: 'INCREMENT' });*/


/*
ReactDOM.render(
	<RIShopReact store={RIShop.STORE} />, document.getElementById("riShopReact")
);*/


import RIShopReact from "./RIShopReact";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
	Stitch,
	RemoteMongoClient,
	AnonymousCredential
} from "mongodb-stitch-browser-sdk";

const client = Stitch.initializeDefaultAppClient('ritmainstituts-shop-rafyj');

const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('ritmainstituts-shop');


class RIShop {
	static STORE = {
		urlBase : '/',// '/ritmainstituts.veikals/dist/',
		logo : {url:'img/ri.logo.green.png', alt:'RI logo'},
		langs : ['lv','en','ru'],
		langDefault: 'lv',
		langActive : 'lv',

		menuMain : { activePathLabel:'', activePathDescription:'', activePath:'', activeIndx: -1,
			items : [
					{label:'DJ TEHNIKA', type:'', prodCount:0, href:'DJ TEHNIKA', _id:'', path:'', descr:''},
					{label:'STUDIJAS TEHNIKA', href:'STUDIJAS TEHNIKA', subMenu:{ activeIndx:-1, items:[
						{label:'DJ TEHNIKA sub 1', prodCount:0, href:'DJ TEHNIKA sub 1', _id:'', path:'', descr:''},
						{label:'DJ TEHNIKA sub 2', href:'DJ TEHNIKA sub 2'},
						{label:'DJ TEHNIKA sub 3', href:'DJ TEHNIKA sub 3'},
					]}},
			{label:'AKSESUĀRI', href:'AKSESUĀRI'},
			{label:'SKANDAS', href:'SKANDAS'}
		]},
		items : [
			{categoryID:'',category:'*none*', title:"Pioneer1", descr:`Viens no jaunākajiem Pioneer produktiem - DJ darba stac\n\t\t\t ija 
		XDJ-R1 MIDI kontrolieris/2 x CD lasītājs/USB flash lasītājs/2 kanālu mikserpults/iebūvēta 
		skUSB flash lasītājs/2 kanālu mikserpults/iebūvēta sk\n\t\t\t USB flash lasītājs/2 kanālu mikserpults/iebūv`, price:10, img:'product.jpg'},
			{title:"Pioneer2", descr:`Viens no jaunākajiem Pioneer produktiem - DJ darba stac\n\t\t\t ija 
		XDJ-R1 MIDI kontrolieris/2 x CD lasītājs/USB flash lasītājs/2 kanālu mikserpults/iebūvēta 
		skUSB flash lasītājs/2 kanālu mikserpults/iebūvēta sk\n\t\t\t USB flash lasītājs/2 kanālu mikserpults/iebūv`, price:20, img:'2018_11_20T13_12_55.147Z_8.jpg'},
			{title:"Pioneer3", descr:`Viens no jaunākajiem Pioneer produktiem - DJ darba stac\n\t\t\t ija 
		XDJ-R1 MIDI kontrolieris/2 x CD lasītājs/USB flash lasītājs/2 kanālu mikserpults/iebūvēta 
		skUSB flash lasītājs/2 kanālu mikserpults/iebūvēta sk\n\t\t\t USB flash lasītājs/2 kanālu mikserpults/iebūv`, price:3, img:'product.jpg'},
			{title:"Pioneer4", descr:`Viens no jaunākajiem Pioneer produktiem - DJ darba stac\n\t\t\t ija 
		XDJ-R1 MIDI kontrolieris/2 x CD lasītājs/USB flash lasītājs/2 kanālu mikserpults/iebūvēta 
		skUSB flash lasītājs/2 kanālu mikserpults/iebūvēta sk\n\t\t\t USB flash lasītājs/2 kanālu mikserpults/iebūv`, price:40, img:'product.jpg'},
			{title:"Pioneer5", descr:`Viens no jaunākajiem Pioneer produktiem - DJ darba stac\n\t\t\t ija 
		XDJ-R1 MIDI kontrolieris/2 x CD lasītājs/USB flash lasītājs/2 kanālu mikserpults/iebūvēta 
		skUSB flash lasītājs/2 kanālu mikserpults/iebūvēta sk\n\t\t\t USB flash lasītājs/2 kanālu mikserpults/iebūv`, price:50, img:'product.jpg'},
			{title:"Pioneer6", descr:`Viens no jaunākajiem Pioneer produktiem - DJ darba stac\n\t\t\t ija 
		XDJ-R1 MIDI kontrolieris/2 x CD lasītājs/USB flash lasītājs/2 kanālu mikserpults/iebūvēta 
		skUSB flash lasītājs/2 kanālu mikserpults/iebūvēta sk\n\t\t\t USB flash lasītājs/2 kanālu mikserpults/iebūv`, price:60, img:'product.jpg'}
		],
		footer : '© 2017 Ritma Institūts Herro',
		labels: {
			'Ielikt grozā' : {'lv':'Ielikt grozā'},
			'Cena' : {'lv':'Cena'},
			'Valūtas simbols' : {'lv' : '€'}
		}
	};
	static hrefStop (e) {
		if (typeof e.preventDefault === 'function') e.preventDefault();
		if (typeof e.preventDefault === 'function') e.stopPropagation();
		if (e.hasOwnProperty('nativeEvent') && typeof e.nativeEvent.stopImmediatePropagation === 'function')
			e.nativeEvent.stopImmediatePropagation();
	}
	static urlSafer (stri : string) {		return stri.replace(/ /ig, '-');	}
	static clickURL (e) {
		RIShop.hrefStop(e);
		let href :string = e.target.href || '';
		if (href.endsWith('/')) href = href.substring(0, href.length-1);
		history.pushState({}, "", href.replace(' ', '-'));
		RIShop.parseAndRender();
		// console.log(href);
	}
	static onHistoryPopState (e) {
		// console.log(window.location.pathname, e);
		RIShop.parseAndRender();
	}
	static parseURLandModifyStore () {
		let pathName = window.location.pathname;
		if (pathName.startsWith(RIShop.STORE.urlBase)) {
			pathName = pathName.substr(RIShop.STORE.urlBase.length);
			// console.log(pathName);
		}
		if (pathName.endsWith('/')) pathName = pathName.substring(0, pathName.length-1);
		let pathSplit :Array<string> = pathName.split('/');
		let lvl1Active = '';
		let lvl2Active = '';
		let lvl1ActiveIndex = -1;
		let lvl2ActiveIndex = -1;
		 //console.log(pathSplit);
		if (pathSplit.length>0) {

			RIShop.STORE.langActive = pathSplit[0] || RIShop.STORE.langDefault;
			if (pathSplit.length>1) {
				lvl1Active = decodeURIComponent(pathSplit[1] as string);
				RIShop.STORE.menuMain.items.forEach((val, indx) => {
					// console.log(lvl1Active, val.label);
					if (lvl1Active == RIShop.urlSafer(val.label)) {
						// console.log(lvl1Active, val);
						RIShop.STORE.menuMain.activeIndx = indx;
						lvl1ActiveIndex = indx;
					}
					if (val.subMenu && val.subMenu.items && pathSplit.length > 2) {
						// console.log('has submenu');
						lvl2Active = decodeURIComponent(pathSplit[2] as string);
						val.subMenu.items.forEach((val2, indx2) => {
							//console.log(lvl2Active, val2.label);
							if (lvl2Active == RIShop.urlSafer(val2.label)) {

								//RIShop.STORE.menuMain.items[indx]['subMenu']['active'] = indx2;
								val.subMenu.activeIndx = indx2;
								lvl2ActiveIndex = indx2;
								//console.log(val.subMenu);
								//RIShop.STORE.menuMain.active = indx;
							}
						});
					}
				});
			}
		}
		RIShop.STORE.menuMain.activePath = '';
		if (lvl1ActiveIndex>-1) {
			RIShop.STORE.menuMain.activePath += lvl1ActiveIndex;
		}
		if (lvl2ActiveIndex>-1) {
			RIShop.STORE.menuMain.activePath += '.'+lvl2ActiveIndex;
		}
		console.log('RIShop.STORE.menuMain.activePath',RIShop.STORE.menuMain.activePath);
		RIShop.STORE.menuMain.activePathLabel = RIShop.catLabelPathfromArrayIDs(RIShop.STORE.menuMain.activePath);
		RIShop.STORE.menuMain.activePathDescription = RIShop.catDescrfromArrayIDs(RIShop.STORE.menuMain.activePath);


		// }
		//});
	}
	static init () {
		//RIShop.parseAndRender();

		client.auth.loginWithCredential(new AnonymousCredential()).then((user) => {
			console.log('logged in anonymous', user);
			return db.collection('products').find({}).asArray();
		}).then((products) => {
			RIShop.STORE.items = [];
			// {title:"Pioneer6", descr:`Viens no jaunākajiem Pioneer produktiem - DJ darba stac\n\t\t\t ija
			// XDJ-R1 MIDI kontrolieris/2 x CD lasītājs/USB flash lasītājs/2 kanālu mikserpults/iebūvēta
			// skUSB flash lasītājs/2 kanālu mikserpults/iebūvēta sk\n\t\t\t USB flash lasītājs/2 kanālu mikserpults/iebūv`, price:60, img:'product.jpg'}
			products.forEach((product:any, indx) => {
				// if (!val.__flexi_parent_cat) { // TOP LEVELS
				//if (product['img_upload']) console.log(product['img_upload'].constructor);
				//let catRef = RIShop.getCatFromCatReference(product['__flexi_cat_reference']);
				RIShop.STORE.items.push({
					categoryID: product['__flexi_cat_reference'],
					// category: catRef.label,
					title: product['title'],
					descr: product['descr'],
					price:123,
					img:Array.isArray(product['img_upload']) ? product['img_upload'][0] : ''});
			});
			// console.log('all products', RIShop.STORE.items);
		}).then(() => {
			return db.collection('categories').find({}).asArray();
		}).then((cats) => {
			//db.collection('categories').find({}).asArray()
			RIShop.STORE.menuMain.items = [];
			cats.forEach((val:any, indx:number) => {
				if (!val.__flexi_parent_cat) { // TOP LEVELS
					RIShop.STORE.menuMain.items.push({_id:val._id.toString() ,label: val['title'], prodCount:RIShop.catProductCount(val._id.toString())
						, href: val['title'], subMenu: null, path: RIShop.STORE.menuMain.items.length.toString(), type:val['type'], descr:val['descr']});
				}
			});
			// console.log(RIShop.STORE.menuMain.items);
			cats.forEach((val:any, indx) => {
				if (val.__flexi_parent_cat) { // 2nd LEVELS
					RIShop.STORE.menuMain.items.forEach((itemL1, indxL1) => {
						if (val.__flexi_parent_cat == itemL1._id) {
							if (!itemL1.subMenu) itemL1.subMenu = {activeIndx: -1, items: []};
							let temp = {_id:val._id.toString() ,label: val['title'], href: val['title'],
								prodCount:RIShop.catProductCount(val._id.toString()), path:itemL1.path+'.'+itemL1.subMenu.items.length
								, descr:val['descr']};
							//console.log(temp);
							itemL1.subMenu.items.push(temp);
						}

					});
					//RIShop.STORE.menuMain.items.push({label: val['title'], href: val['title'], subMenu: null});
				}
				//console.log(RIShop.STORE.menuMain.items);
			});

			RIShop.STORE.items.forEach((product:any, indx) => {
				//console.log(product);
				product.catPath = RIShop.getCatFromCatReference(product.categoryID).path;
				//console.log(product.categoryID, product.catPath);
				// if (product.catPath)
				product.catPathTitleComposite = RIShop.catLabelPathfromArrayIDs(product.catPath, product);
				console.warn('catPathComposite', product.catPath, product.title,  product.catPathComposite);
			});
			console.log('all items', RIShop.STORE.items);
			console.log('menu', RIShop.STORE.menuMain);
		}).then(() => {
			RIShop.parseAndRender();
		}).catch(err => {
			console.warn('Stitch Error', err)
		});
	}
	static catDescrfromArrayIDs (path :string) {
		let ret = '';
		let split = path.split('.');
		if (split.length>0 && path) {
			ret = RIShop.STORE.menuMain.items[split[0]]['descr'];
		}
		if (split.length>1) {
			ret = RIShop.STORE.menuMain.items[split[0]].subMenu.items[split[1]]['descr'];
		}
		return ret;
	}
	static catLabelPathfromArrayIDs (path :string, product :any = null) {
		let ret = [];
		let split = path.split('.');
		//let path = product.catPath;
		if (path=='' && product) console.warn('empty path for ', product);
		if (split.length>0 && path) {
			console.log(path, RIShop.STORE.menuMain.items[split[0]].label);
			ret.push(RIShop.STORE.menuMain.items[split[0]].label);
		}
		if (split.length>1) {
			console.log(path, RIShop.STORE.menuMain.items[split[0]].subMenu.items[split[1]].label);
			ret.push(RIShop.STORE.menuMain.items[split[0]].subMenu.items[split[1]].label);
		}
		return ret.join(' > ');
	}
	static catProductCount (id :string) {
		let count = 0;
		RIShop.STORE.items.forEach((prod, indx) => {
			 //console.log(prod.categoryID, id);
			if (prod.categoryID == id) {
				count++;
			}
		});
		return count;
	}
	static getCatFromCatReference (_id :string) :any {
		let ret:any = '';
		// console.log(_id, RIShop.STORE.menuMain.items);
		if (Array.isArray(_id)) _id = _id[0];
		//console.log('getCatFromCatReference', _id);
		RIShop.STORE.menuMain.items.forEach((val, indx) => {
			console.log('***',_id, val._id);
			if (val._id == _id) {	ret = val;}
			if (val.subMenu && val.subMenu.items && !ret) {
				val.subMenu.items.forEach((val2, indx2) => {
					console.log('---', _id, val2._id);
					if (val2._id == _id) {	ret = val2;}
				});
			}
		});
		if (!ret) ret = {path:''};
		return ret;
	}
	static parseAndRender () {
		RIShop.parseURLandModifyStore();
		RIShop.render();
	}
	static render () {
		ReactDOM.render(<RIShopReact store={RIShop.STORE} />, document.getElementById("riShopReact"));
	}
}

window.onpopstate = RIShop.onHistoryPopState;
export default RIShop;